.form-application-page {
  padding: 50px 0 80px;
  .space {
    height: 20px;
  }
  h3 {
    margin-bottom: 46px;
    color: #222;
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
  }
  h4 {
    padding-bottom: 15px;
    margin: 45px 0 20px;
    border-bottom: 1px solid #ced0d6;
    color: #000;
    font-weight: 400;
    font-size: 23px;
    line-height: 24px;
    &:first-child {
      margin-top: 0;
    }
  }
  ol {
    margin:0 0 40px 20px;
    li {
      margin-bottom: 10px;
      list-style-type: decimal;
    }
  }
  p {
    .red {
      color: #ff0101;
    }
  }
}
.application-form {
  padding: 50px;
  background: #f6f6f6;
  .inline-ad {
    display: flex;
    align-items: center;
    label {
      width: 20px;
      margin-bottom: 0;
    }
    input {
      flex: 1;
    }
  }
  label {
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
  .form-check {
    margin-bottom: 10px;
    label {
      color: #5c5f69;
      font-weight: 400;
    }
  }
  .form-btns {
    margin-top: 40px;
    padding-top: 30px;
    border-top: 1px solid #ced0d6;
  }
}

@media only screen and (max-width: 1199px) {
  .form-application-page {
    h3 {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 26px;
    }
    h4 {
      font-size: 21px;
      line-height: 21px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .application-form {
    padding: 20px 30px;
    .form-btns {
      padding-top: 20px;
    }
  }
  .form-application-page {
    padding: 40px 0 60px;
    h3 {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 26px;
    }
    h4 {
      padding-bottom: 10px;
      font-size: 18px;
      line-height: 18px;
    }
    p .red {
      display: block;
    }
  }
}

@media only screen and (max-width: 767px) {
  .application-form {
    padding: 20px;
  }
  .form-application-page {
    padding: 30px 0 50px;
    h3 {
      margin-bottom: 20px;
      font-size: 19px;
      line-height: 23px;
      font-weight: 600;
    }
    h4 {
      padding-bottom: 10px;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .application-form {
    .form-btns {
      margin-top: 20px;
    }
  }
  .form-application-page {
    padding: 20px 0 40px;
    h3 {
      font-size: 17px;
    }
    .space {
      height: 15px;
    }
    p {
      margin-bottom: 15px;
      font-size: 14px;
    }
    ol li {
      font-size: 14px;
    }
  }
}
